import { resultJudge200 } from '@/utlis/facthJudge';
import { reqDeptList } from "@/api";
const state = {
    flowStateId: '',//表单类型id
    formTitle: '',//表单标题
    userId: 4, //执行人id
    userName: '王雅雯', //执行人name
    flowId: '', //流程id
    formData: {}, //表单数据
    tblNodes: [], //流程节点
    deptList: [], //部门
    userList: [], //用户
    fileIDs: [], //  用户上传文件的id
    filesAction: true, //用户文件是否完成上传
    deptId: "",
};

const mutations = {
    SET_DEPT_ID(state, data) {
        state.deptId = data;
    },
    SET_USER_ID(state, data) {
        
        state.userId = data.id;
        state.userName = data.nickName;
    },
    SET_DEPT_LIST(state, data) {
        state.deptList = data;
    },
    SET_FLOW_ID(state, data) {
        state.flowId = data;
    },
    SET_FLOW_NODES(state, data) {
        state.tblNodes = data;
    },
    SET_FORM_DATA(state, data) {
        state.formData = data;
    },
    SET_FILES(state, data) {
        state.fileIDs = data;
    },
    SET_FILES_UPLOAD(state, data) {
        state.filesAction = data;
    },
    SET_FORM_TITLE(state, data) {

        state.flowStateId = data.flowState;
        state.formTitle = data.name;
    }
};

const actions = {
    /**
    * 保存执行人id 以及name
     */
    set_user_id({ commit }, data) {
        commit("SET_USER_ID", data);
    },
    /**
     * 获取部门列表
     */
    async get_dept_list({ commit }) {

        if (state.deptList.length === 0) {
            let result = await reqDeptList();
            if (resultJudge200(result)) {
                commit("SET_DEPT_LIST", result.data);
            }
        }

    },
    /**
     * 保存流程id 
     */
    set_flow_id({ commit }, data) {
        commit("SET_FLOW_ID", data);
    },
    /**
     * 保存流程节点
     */
    set_flow_node({ commit }, data) {
        commit("SET_FLOW_NODES", data);
    },
    /**
     * 保存表单数据
     */
    set_form_data({ commit }, data) {
        commit("SET_FORM_DATA", data);
    },
    /**
     * 保存用户所选文件id
     */
    set_files({ commit }, data) {
        commit("SET_FILES", data);
    },
    /**
     * 设置文件是否上传中
     */
    set_files_upload({ commit }, data) {
        commit("SET_FILES_UPLOAD", data);
    },
    /**
     * 设置表单基础数据
     * @param {*} data 
     */
    set_form_title({ commit }, data) {

        commit("SET_FORM_TITLE", data);
    }
};

const getters = {};

export default {
    state,
    mutations,
    actions,
    getters,
};
