import { reqUsers, reqDeptList } from "@/api";
import { resultJudge200 } from "@/utlis/facthJudge";

const state = {
    deptList: [],
    userListData: {},
    deptId: '',
    roleId: '',
    industryTitle: '',
    userCount: 0
};

const mutations = {
    SET_DEPT_ID(state, data) {
        state.deptId = data;
    },
    SET_ROLE_ID(state, data) {
        state.roleId = data;
    },
    SET_USER_DATA(state, data) {
        state.userListData = data;
    },
    SET_DEPT_DATA(state, data) {
        state.deptList = data;
    },
    SET_INDUSTRY_TITLE(state, data) {
        state.industryTitle = data;
    },
    SET_USER_COUNT(state, data) {
        state.userCount = data;
    }
};
const actions = {
    /**
     * @description: 获取用户列表
     * @return {*}
     */
    async getUserList({ commit }, val) {
        let regex = /(deptId|roleId)/;
        let result = await reqUsers(val);
        if (resultJudge200(result)) {
            // 不查询所用户时
            if (!regex.test(val)) {
                commit("SET_USER_COUNT", result.data.list.length);
            };
            commit("SET_USER_DATA", result.data);
        } else {
            commit("SET_USER_DATA", []);
        }
        return true;
    },
    /**
     * @description: 获取部门列表
     * @return {*}
     */
    async getDeptList({ commit }) {
        let result = await reqDeptList();
        if (resultJudge200(result)) {
            commit('SET_DEPT_DATA', result.data)
            return result;
        }
    }
};

const getters = {
    deptId: (state) => {
        return state.deptId;
    },
    roleId: (state) => {
        return state.roleId;
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
};
