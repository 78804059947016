
/**
 * @description: 判断接口返回是否为200
 * @return {*}
 * @param {*} data
 */
const resultJudge200 = (data) => {

    try {
        if (data.code && data.code === 200) {
            return true
        } else {
            return false
        }
    } catch (error) {

    }


}

export { resultJudge200 }