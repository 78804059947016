export default [
    {
        path: '/form_field', //表单页
        component: () => import('@/views/Admin/formSetting/Forms'),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/stamp', //资料表单
                component: () => import('@/views/Admin/formSetting/Forms/Modules/stamp.vue'),
                meta: {
                    component: 'stamp',
                    requiresAuth: true
                }
            },
            {
                path: '/request_funds', //请款表单
                component: () => import('@/views/Admin/formSetting/Forms/Modules/requestFunds.vue'),
                meta: {
                    component: 'requestFunds',
                    requiresAuth: true
                }
            },
            {
                path: '/expense_account', //报销表单
                component: () => import('@/views/Admin/formSetting/Forms/Modules/expenseAccount.vue'),
                meta: {
                    component: 'expenseAccount',
                    requiresAuth: true
                }
            },
            {
                path: '/information', //资料表单
                component: () => import('@/views/Admin/formSetting/Forms/Modules/information.vue'),
                meta: {
                    component: 'information',
                    requiresAuth: true
                }
            },
            {
                path: '/invoice', //发票表单
                component: () => import('@/views/Admin/formSetting/Forms/Modules/invoice.vue'),
                meta: {
                    component: 'invoice',
                    requiresAuth: true
                }
            },
            {
                path: '/notice', //公告表单
                component: () => import('@/views/Admin/formSetting/Forms/Modules/notice.vue'),
                meta: {
                    component: 'notice',
                    requiresAuth: true
                }
            },
            {
                path: '/imprest', //备用金表单
                component: () => import('@/views/Admin/formSetting/Forms/Modules/imprest.vue'),
                meta: {
                    component: 'imprest',
                    requiresAuth: true
                }
            },
            {
                path: '/procure', //采购表单
                component: () => import('@/views/Admin/formSetting/Forms/Modules/procure.vue'),
                meta: {
                    component: 'procure',
                    requiresAuth: true
                }
            },
            {
                path: '/contract', //合同表单
                component: () => import('@/views/Admin/formSetting/Forms/Modules/contract.vue'),
                meta: {
                    component: 'contract',
                    requiresAuth: true
                }
            },
            {
                path: '/recruitment', //招聘
                component: () => import('@/views/Admin/formSetting/Forms/Modules/recruitment'),
                meta: {
                    component: 'recruitment',
                    requiresAuth: true
                }
            },
            {
                path: '/leave', //请假
                component: () => import('@/views/Admin/formSetting/Forms/Modules/leave'),
                meta: {
                    component: 'leave',
                    requiresAuth: true
                }
            },
            {
                path: '/privateCar', //私车公用
                component: () => import('@/views/Admin/formSetting/Forms/Modules/privateCar'),
                meta: {
                    component: 'privateCar',
                    requiresAuth: true
                }
            },
            {
                path: '/applicationVehicle', //申请用车
                component: () => import('@/views/Admin/formSetting/Forms/Modules/applicationVehicle'),
                meta: {
                    component: 'applicationVehicle',
                    requiresAuth: true
                }
            },
            {
                path: '/PerfBM', //绩效审批-基层管理人员
                component: () => import('@/views/Admin/formSetting/Forms/Modules/PerfBM.vue'),
                meta: {
                    component: 'PerfBM',
                    requiresAuth: true
                }
            },
            {
                path: '/PerfSalesman', //绩效审批-业务销售
                component: () => import('@/views/Admin/formSetting/Forms/Modules/PerfSalesman.vue'),
                meta: {
                    component: 'PerfSalesman',
                    requiresAuth: true
                }
            },
            {
                path: '/PerfAO', //绩效审批-高级管理人员
                component: () => import('@/views/Admin/formSetting/Forms/Modules/PerfAO.vue'),
                meta: {
                    component: 'PerfAO',
                    requiresAuth: true
                }
            },
            {
                path: '/PerfStaff', //绩效审批-员工级
                component: () => import('@/views/Admin/formSetting/Forms/Modules/PerfStaff.vue'),
                meta: {
                    component: 'PerfStaff',
                    requiresAuth: true
                }
            }
        ]
    },
]