/*
 * @Author: TheodoreZC 1340990981@qq.com
 * @Date: 2023-11-03 16:29:12
 * @LastEditors: TheodoreZC 1340990981@qq.com
 * @LastEditTime: 2024-02-22 09:23:23
 * @FilePath: /CRM/src/store/moduls/menus.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { reqMainMenus } from '@/api';
import { resultJudge200 } from '@/utlis/facthJudge';

const state = {
    MenusArr: [],
    MenusIndex: 1,
    codeAction: 0,
    isModule:1,
};

const mutations = {
    SETMENUSDATA(state, data) {
        state.MenusArr = data;
    },
    SET_MENUS_INDEX(state, data) {
        state.MenusIndex = data;
    },
    SET_CODE_ACTION(state, data) {
        state.codeAction = data;
    },
    SET_IS_MODULE(state,data){
        state.isModule = data;
    }
};
const actions = {
    /**
     * @description: 获取菜单列表
     * @return {*}
     * @param {*} commit
     */
    async getMainMenus({ commit }) {
        let result = await reqMainMenus();
        if (resultJudge200(result)) {
            commit('SETMENUSDATA', result.data);
        }
    },
};

const getters = {};

export default {
    state,
    mutations,
    actions,
    getters,
};
