import { reqUserLogin, reqLogout, } from "@/api";
import { setToken, getToken, removeToken } from '@/utlis/token';

const state = {
    token: getToken(),
    nikeName: '',
    userId: ''
};

const mutations = {
    //存储用户信息
    USERINFO(state, data) {
        state.token = data.Authorization;
        state.nikeName = data.nikeName;
        state.userId = data.userId;
    },
    //清除本地数据
    CLEAR(state) {
        state.token = '';
        state.nikeName = '';
        state.userId = '';
        //清除本地存储中的token
        removeToken();
    },
    //刷新存储的token
    SETTOKEN(state) {
        state.token = getToken();
    }
};
const actions = {
    /**
     * @description: 登陆业务
     * @return {*}
     * @param {*} commit
     * @param {*} data
     */
    async userLogin({ commit }, data) {

        let result = await reqUserLogin(data);

        if (result.code === 401) {

            return result.message;
        }

        if (result.code === 200) {
            commit('USERINFO', result.data);
            setToken(result.data.Authorization);
            return 'succeed';
        }


    },
    /**
     * @description: 退出登录
     * @return {*}
     * @param {*} commit
     */
    async userLogout({ commit }) {
        let result = await reqLogout();

        if (result.code == 200) {

            commit("CLEAR");
            sessionStorage.removeItem('vuex'); //清除vuex缓存

            return [true, '退出登录成功!'];

        } else {

            return [false, result];
        }
    },
    //修改token
    setToken({ commit }) {
        commit("SETTOKEN");
    }
};

const getters = {};

export default {
    state,
    mutations,
    actions,
    getters,
};
