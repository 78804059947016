
import VueRouter from "vue-router";
import Vue from "vue";

Vue.use(VueRouter);

// //捕获调用push切换到同一路由时报错的异常。
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }

// //捕获调用replace切换到同一路由时报错的异常。
// const originalReplace = VueRouter.prototype.replace
// VueRouter.prototype.replace = function replace(location) {
//     return originalReplace.call(this, location).catch(err => err)
// }

/**
 * 初始路由
 */
export const constantRoutes = [
    {
        path: '/login', //登陆页
        name: 'login',
        component: () => import('@/views/Login'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/manage', //后台主页
        redirect: '/manage/systemconfig', //直接在这里设置重定向
        component: () => import('@/layout/Main'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/person', //主页
        name: 'person',
        component: () => import('@/layout/PersonSet'),
        meta: {
            requiresAuth: true
        }
    },
];

/**
 * @Author: CJH
 * @Date: 2023-11-02 11:41:28
 * @description: 创建router实例
 * @return {*}
 */
const createRouter = () => new VueRouter({
    scrollBehavior: () => ({ y: 0 }), //当切换路由时，页面滚动至top 0
    routes: constantRoutes
});

const router = createRouter();





export default router;

