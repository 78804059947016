/**
 * @description: 筛选请求是否显示进度加载条
 * @return {*}
 * @param {*} url
 */
export function shouldShowLoadingBar(url) {

    const specificUrls = ["/user/code", "/sysMenu/mainMenus"]; // 不显示加载条的网络请求

    return !specificUrls.includes(url);
}

/**
 * 节流
 * @param {*} fn 
 * @param {*} delay 
 * @returns 
 */
export function debounce(fn, delay) {
    let timer = null;
    return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(this, arguments);
        }, delay);
    };
}

export function getUserToTaskExamine(id) {
    let usersId = [2, 6];
    return usersId.includes(Number(id));
}

/**
 * 数字转大写
 * @param numbar num 数字
 */
export const NUMBER_TO_CHINESE = (num) => {
	if (isNaN(num)) return '输入的不是数字';

	// 定义数字对应的大写字符
	const bigNumbers = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
	// 定义整数部分的单位
	const units = ['', '拾', '佰', '仟', '万', '拾', '佰', '仟', '亿', '拾', '佰', '仟', '兆', '拾', '佰', '仟'];
	// 定义小数部分的单位
	const smallUnits = ['角', '分'];

	// 分割整数和小数部分
	const [integerPart, decimalPart] = num.toFixed(2).split('.');

	let chineseStr = '';

	// 处理整数部分
	let integerLength = integerPart.length;
	for (let i = 0; i < integerLength; i++) {
		const currentNum = integerPart.charAt(i);
		const currentUnit = units[integerLength - i - 1];
		if (currentNum !== '0') {
			chineseStr += bigNumbers[parseInt(currentNum)] + currentUnit;
		} else {
			// 处理多余的“零”
			if (chineseStr[chineseStr.length - 1] !== '零') {
				chineseStr += '零';
			}
		}
	}

	// 去掉多余的“零”
	chineseStr = chineseStr.replace(/零+$/, '');

	if (!chineseStr) {
		chineseStr = '零元';
	} else {
		chineseStr += '元';
	}

	// 处理小数部分
	if (decimalPart !== '00') {
		if (decimalPart[0] !== '0') {
			chineseStr += bigNumbers[parseInt(decimalPart[0])] + smallUnits[0]; // 角
		}
		if (decimalPart[1] !== '0') {
			chineseStr += bigNumbers[parseInt(decimalPart[1])] + smallUnits[1]; // 分
		}
	} else {
		chineseStr += '整';
	}

	// 返回最终转换结果
	return chineseStr;
}