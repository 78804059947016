import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

// 用户信息
import user from "./moduls/user";
// 主页菜单
import menus from "./moduls/menus";
//权限管理
import permission from "./moduls/permission";
//部门与员工管理
import deptUsers from "./moduls/deptUsers";
//oa表单数据
import formData from "./moduls/formData";
// oa 审核列表
import taskExamine from "./moduls/taskExamine";
//公共
import common from "./moduls/common";
//CRM菜单
import crmMenus from "./moduls/crmMenus";

export default new Vuex.Store({
  modules: {
    user,
    menus,
    permission,
    deptUsers,
    formData,
    taskExamine,
    common,
    crmMenus
  },
  plugins: [
    // 使用 vuex-persistedstate 插件
    createPersistedState({
      // 指定要持久化的模块和其中的某一个值
      paths: ['user', 'menus.MenusArr', 'permission.permissionList', 'menus.MenusIndex'],
      storage: window.localStorage
    })
  ]
});
