import Vue from 'vue';
import App from './App.vue';
// import element from './element';
import { message } from './utlis/elMessage';
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
Vue.use(ElementUI);
Vue.prototype.$message = message;
//less预编译
import less from 'less';
//路由
import router from '@/router';
import "@/router/permission";
//全局重置样式
import '@/assets/style/reset.css';
//引入仓库
import store from "@/store";


Vue.use(less);

//关闭生产提示
Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
