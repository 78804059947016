import router, { asyncRouterMap } from "@/router";

const state = {
    permissionList: null,
    isGetterRouter: false
};

const mutations = {
    SET_PERMISSION(state, routes) {
        state.permissionList = routes;
    },
    CHANG_GETTER_ROUTER(state, value) {
        state.isGetterRouter = value;
    }
};
const actions = {
};

const getters = {

};

export default {
    state,
    mutations,
    actions,
    getters,
};