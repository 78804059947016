// 一套默认主题以及一套暗黑主题
export const themes = {
  light: {
    baseColor: `${249}, ${249},${249}`, // 基色（无变化） 
    pageBgColor: `${240}, ${240},${240}`, // 页面的背景色
    scrollBgColor: `${0}, ${0},${0}`, // 滚动条的背景色
    BoxBgColor: `${255}, ${255},${255}`, // 背景色-盒子
    BorderColor: `${231}, ${231},${231}`, // 边框颜色
    TextColor: `${0}, ${0},${0}`, // 文字
  },
  dark: {
    baseColor: `${25}, ${121},${255}`, // 基色（无变化） 
    pageBgColor: `${0}, ${0},${0}`, // 页面的背景色
    scrollBgColor: `${255}, ${255},${255}`, // 滚动条的背景色
    BoxBgColor: `${0}, ${50},${109}`, // 背景色-盒子
    BorderColor: `${84}, ${84},${84}`, // 边框颜色
    TextColor: `${255}, ${255},${255}`, // 文字
    SecondTextColor: `${151}, ${151},${151}`, // 文字
  },
};
