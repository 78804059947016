const state = {
  menuName: "mainList",
};

const mutations = {
  SET_CRM_MENU(state, name) {
    state.menuName = name;
  },
};
const actions = {};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
