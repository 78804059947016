import store from "@/store";
import router from "./index";
import admin from "./moduls/admin";
import forms from "./moduls/forms";
import defaultRoute from "./moduls/defaultRoute";
import oa from "./moduls/oa";

router.beforeEach((to, from, next) => {

    if (to.name === "login" || to.push == "/") {
        localStorage.removeItem("Authorization");
        next()
    } else {

        //(未登录)
        if (!localStorage.getItem("Authorization")) {

            next({
                path: "/login"
            });

        } else {
            //已登录
            if (!store.state.permission.isGetterRouter) {
                ConfigRouter();

                next({
                    path: to.fullPath //因为刚添加完路由所以让如有重新走一遍
                });

            } else {
                next();
            }

        }
    }
});

export const asyncRouterMap = [
    { type: 'manage', routers: admin }, //后台
    { type: 'formField', routers: forms }, //表单
    { type: 'oa', routers: oa }, //审批
    { type: 'default', routers: defaultRoute } //其他路由
];

const ConfigRouter = () => {
    asyncRouterMap.forEach(item => {
        if (item.type === 'default') {
            item.routers.forEach(item2 => {
                router.addRoute(item2);
            })
        } else {
            item.routers.forEach(item2 => {
                router.addRoute(item.type, item2);
            })
        }
    });
    store.commit("CHANG_GETTER_ROUTER", true);
}
