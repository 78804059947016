

const state = {
    EditFormCon: false,
    EditFormDate: {}
};

const mutations = {
    SET_EDIT_FORM_CON(state, data) {
        state.EditFormCon = data
    },
    SET_FORM_DATA_EDIT(state, data) {
        state.EditFormDate = data
    }
};
const actions = {

};

const getters = {

};

export default {
    state,
    mutations,
    actions,
    getters,
};
