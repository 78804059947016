export default [
    {
        path: '/index', //主页
        name: 'index',
        redirect: '/index/taskExamine_wait', //直接在这里设置重定向
        component: () => import('@/layout/IndexMain'),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'taskExamine_my',
                name: 'taskExamineMy',
                component: () => import('@/views/Oa/taskExamine'),
            },
            {
                path: 'taskExamine_wait',
                name: 'taskExamineWait',
                component: () => import('@/views/Oa/taskExamineWait'),
            },
            {
                path: "execute",
                name: "execute",
                component: () => import("@/views/Oa/execute")

            },
            {
                path: "performance",
                name: "performance",
                component: () => import("@/views/Oa/performance")
            },
            {
                path: "statistics",
                name: "statistics",
                component: () => import("@/views/Oa/statistics")
            },
            {
                path: "leaveBalance",
                name: "leaveBalance",
                component: () => import("@/views/Oa/leaveBalance")
            }
        ]
    },

]