

import axios from "axios";
import store from "@/store";
import { setToken, removeToken } from "@/utlis/token";
import router from "@/router";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import { shouldShowLoadingBar } from "@/utlis/common";

//请求配置
let requests = axios.create({
  // baseURL: "http://8.134.163.54/crm", //线上地址
  // baseURL: "http://192.168.2.88:8888/crm", //本地地址
  // baseURL: "api/",
  baseURL: "/crm", //打包
  timeout: 50000,
});

//请求拦截器
requests.interceptors.request.use((config) => {

  //不需要携带token的请求
  const NoToken = ["/user/login", "/user/code", "/user/captcha"];
  let v = NoToken.some(path => config.url.startsWith(path));
  if (!v) {

    config.headers.Authorization = store.state.user.token;
  }


  //开启页面顶部加载条动画
  if (shouldShowLoadingBar(config.url)) {
    nprogress.start();
  }

  return config;
});

//响应拦截器
requests.interceptors.response.use(
  (res) => {

    //动态刷新更新最新token并存储
    if (res.headers.authorization) {
      setToken(res.headers.authorization);
      store.dispatch('setToken');
    }

    //用户认证失败
    if (res.data.code === 401 && router.currentRoute.path !== '/login') {
      router.push("/login");
      removeToken();
    }

    //token过期 567
    if (res.data.code === 567) {
      removeToken();
      router.push("/login");
    }

    //关闭页面顶部加载条动画
    if (shouldShowLoadingBar(res.config.url)) {
      nprogress.done();
    }

    return res.data;
  },
  (err) => {
    if (err.response && err.response.status === 401) {
      // 如果状态码是 401，则跳转到登录页
      router.push("/login"); // 假设登录页的路由是 '/login'，请根据自己的项目修改
    } else {
      // 不是 401 错误，或者没有响应体，正常抛出错误
      Promise.reject(err);
    }
  }
);

export default requests;