export default [
    {
        path: "/",
        component: () => import('@/views/Login'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/:pathMatch(.*)*",
        name: "Notfound",// 404
        component: () => import('@/views/components/404.vue'),
        meta: {
            requiresAuth: false
        }
    }
]