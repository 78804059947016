

const state = {
    state: 0,
    deptData: [],
    formDataList: {}
};

const mutations = {
    SET_STAMP_TYPE(state, data) {
        state.state = data;
    },
    SET_DEPT_LIST(state, data) {
        state.deptData = data
    },
    SET_FORM_DATA_READ(state, data) {
        state.formDataList = data
    }
};
const actions = {

};

const getters = {};

export default {
    state,
    mutations,
    actions,
    getters,
};
