import { themes } from "./model";

/**
 * @Author: CJH
 * @Date: 2023-11-02 10:30:29
 * @description: 修改页面中的样式变量值
 * @param {*} 主题样式
 */
const changeStyle = (obj) => {
  for (let key in obj) {
    document
      .getElementsByTagName("body")[0]
      .style.setProperty(`--${key}`, obj[key]);
  }
};

/**
 * @Author: CJH
 * @Date: 2023-11-02 10:32:50
 * @description: 变更主题
 * @param {String} themeName
 */
export const setTheme = (themeName) => {
  localStorage.setItem("theme", themeName); // 保存主题到本地，下次进入使用该主题
  const themeConfig = themes[themeName] ? themes[themeName] : themes['light'];
  changeStyle(themeConfig);
};

/**
 * @Author: CJH
 * @Date: 2023-11-02 10:23:57
 * @description: 获取是否存在历史主题
 */
export const getHistoryTheme = () => {
  let theme = localStorage.getItem("theme");
  let themeConfig = theme ? theme : 'light';
  setTheme(themeConfig);
}