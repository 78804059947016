
export default [
    {
        path: '/manage', //后台主页
        name: 'manage',
        redirect: '/manage/systemconfig',
        component: () => import('@/layout/Main'),
        meta: { permissions: ['manage', 'system', 'read'], requiresAuth: true },
        children: [
            {
                name: 'systemconfig',
                path: 'systemconfig',// 系统修改页
                component: () => import('@/views/Admin/Config'),
                meta: { title: '企业首页', icon: { class: 'icon-home', color: '#2362fb' }, requiresAuth: true }
            },
            {
                name: 'employeeDep',
                path: 'employeeDep', //部门于员工
                component: () => import('@/views/Admin/EmployeeDep'),
                meta: { title: '员工与部门管理', icon: { class: 'icon-renyuan', color: 'rgb(38, 212, 218)' } }
            },
            {
                name: 'permissions',
                path: 'permissions', //权限管理
                component: () => import('@/views/Admin/Permissions'),
                meta: { title: '权限管理', icon: { class: 'icon-edit-permissions', color: 'rgb(0 159 164)' } }
            },
            {
                name: 'examine',
                path: 'examine', //审批流程
                component: () => import('@/views/Admin/Examine'),
                meta: { title: '审批流程', icon: { class: 'icon-menus', color: 'rgb(88, 100, 255)' } }
            },
            {
                name: 'menuManager',
                path: 'menuManager', //菜单管理
                component: () => import('@/views/Admin/MenuManager'),
                meta: { title: '菜单管理', icon: { class: 'icon-shenpi', color: 'rgb(255, 185, 64)' } }
            },
            {
                name: 'systemLog',
                path: 'systemLog', //菜单管理
                component: () => import('@/views/Admin/log'),
                meta: { title: '系统日志', icon: { class: 'icon-rizhi', color: 'rgb(35, 98, 251)' } }
            },
            {
                name: 'formSetting',
                path: 'formSetting', //表单管理
                component: () => import('@/views/Admin/formSetting'),
                meta: { title: '表单管理', icon: { class: 'icon-daibanshixiang', color: 'rgb(255 90 46)' } }
            }
        ]
    },

]




